import ActivityLog from 'pages/activity-log';
import ProductTagAutomation from 'pages/automations/product-tag';
import BrandReport from 'pages/brand-report';
import HomePage from 'pages/cms/home-page';
import MappingSize from 'pages/data-mapping/size';
import OrderList from 'pages/order-list';
import OrderDetail from 'pages/order-list/order-detail';
import MergeSuggestion from 'pages/product/merge-suggestion';
import ProductSuggestion from 'pages/product/merge-suggestion/product-list';
import ProductManagement from 'pages/product/product-management';
import ProductForm from 'pages/product/product-management/form';
import RetailerList from 'pages/retailer';
import RetailerForm from 'pages/retailer/retailer-form';
import SalesReport from 'pages/sales-report';
import Settings from 'pages/settings';
import ShopifyOrderList from 'pages/shopify-order-list';
import ShopifyOrderDetail from 'pages/shopify-order-list/order-detail';
import UserManagement from 'pages/user-management';
import UserDetail from 'pages/user-management/user-detail';
import UserForm from 'pages/user-management/user-form';

const coreRoutes = [
  {
    path: '/settings',
    title: 'Settings',
    component: Settings
  },
  {
    path: '/product/product-management',
    title: 'Product Management',
    component: ProductManagement
  },
  {
    path: '/product/product-management/:id',
    title: 'Product Form',
    component: ProductForm
  },
  {
    path: '/product/merge-suggestion',
    title: 'Merge Suggestion',
    component: MergeSuggestion
  },
  {
    path: '/product/merge-suggestion/:id',
    title: 'Product Suggestion',
    component: ProductSuggestion
  },
  {
    path: '/data-mapping/size',
    title: 'Mapping Sizes',
    component: MappingSize
  },
  {
    path: '/orders/boutique',
    title: 'Boutique Order',
    component: OrderList
  },
  {
    path: '/orders/boutique/:id',
    title: 'Order Detail',
    component: OrderDetail
  },
  {
    path: '/orders/shopify',
    title: 'Shopify Order',
    component: ShopifyOrderList
  },
  {
    path: '/orders/shopify/:id',
    title: 'Order Detail',
    component: ShopifyOrderDetail
  },
  {
    path: '/activity-log',
    title: 'Activity Log',
    component: ActivityLog
  },
  {
    path: '/retailers',
    title: 'Retailer',
    component: RetailerList
  },
  {
    path: '/retailers/:id',
    title: 'Retailer Form',
    component: RetailerForm
  },
  {
    path: '/user-management',
    title: 'User Management',
    component: UserManagement
  },
  {
    path: '/user-management/:id',
    title: 'User Management',
    component: UserDetail
  },
  {
    path: '/user-management/new',
    title: 'Create User',
    component: UserForm
  },
  {
    path: '/report/brand-report',
    title: 'Brand Report',
    component: BrandReport
  },
  {
    path: '/report/sales-report',
    title: 'Sales Report',
    component: SalesReport
  },
  {
    path: '/cms/home-page',
    title: 'Home Page',
    component: HomePage
  },
  {
    path: '/automation/product-tags',
    title: 'Product Tag Automation',
    component: ProductTagAutomation
  }
];

const routes = [...coreRoutes];
export default routes;
