// cookie keys
export const COOKIE_ACCESS_TOKEN = 'baltini_erp_access_token';

// react query keys
export const RQ_CURRENT_USER = 'rq_current_user';
export const RQ_PRODUCT_LIST = 'rq_product_list';
export const RQ_PRODUCT_DETAIL = 'rq_product_list';
export const RQ_ORDER_LIST = 'rq_order_list';
export const RQ_ORDER_DETAIL = 'rq_order_detail';
export const RQ_USER_MANAGEMENT_LIST = 'rq_user_management_list';
export const RQ_USER_MANAGEMENT_DETAIL = 'rq_user_management_detail';
export const RQ_USER_MANAGEMENT_CREATE = 'rq_user_management_create';
export const RQ_MERGE_SUGGESTION_LIST = 'rq_merge_suggestion_list';
export const RQ_MAPPING_SIZE_LIST = 'rq_mapping_size_list';
export const RQ_MAPPING_SIZE_HISTORY = 'rq_mapping_size_history';
export const RQ_MAPPING_SIZE_DETAIL_ORIGINAL = 'rq_mapping_size_detail_original';
export const RQ_MAPPING_SIZE_DETAIL_STANDARDIZED = 'rq_mapping_size_detail_standardized';
export const RQ_BOUTIQUE_LIST = 'rq_boutique_list';
export const RQ_CATEGORY_LIST = 'rq_category_list';
export const RQ_BRAND_LIST = 'rq_brand_list';
export const RQ_BRAND_REPORT = 'rq_brand_report';
export const RQ_SALES_REPORT = 'rq_sales_report';
export const RQ_MERGE_SUGGESTION_VENDOR_LIST = 'rq_merge_suggestion_vendor_list';
export const RQ_MERGE_SUGGESTION_CATEGORY_LIST = 'rq_merge_suggestion_category_list';
export const RQ_MERGE_SUGGESTION_PRODUCT_TYPE_LIST = 'rq_merge_suggestion_product_type_list';
export const RQ_AUDIT_TRAILS = 'rq_audit_trails';
export const RQ_AUDIT_TRAILS_DOWNLOAD_LIST = 'rq_audit_trails_download_list';
export const RQ_AUDIT_TRAILS_PAGES = 'rq_audit_trails_pages';
export const RQ_AUDIT_TRAILS_ACTIONS = 'rq_audit_trails_actions';
export const RQ_RETAILER_LIST = 'rq_retailer_list';
export const RQ_RETAILER_BY_ID = 'rq_retailer_detail';
export const RQ_RETAILER_ADDRESS_LIST = 'rq_retailer_address_list';
export const RQ_CMS_HOME_PAGE = 'rq_cms_home_page';
export const RQ_BALTINI_COLLECTIONS = 'rq_baltini_collections';
export const RQ_PRODUCT_TAG_AUTOMATION_LIST = 'rq_product_tag_automation_list';
export const RQ_PRODUCT_TAG_AUTOMATION_DETAIL = 'rq_product_tag_automation_detail';

export const RQ_COMMON_VENDOR_LIST = 'rq_common_vendor_list';
export const RQ_COMMON_CATEGORY_LIST = 'rq_common_category_list';
export const RQ_COMMON_RETAILER_LIST = 'rq_common_retailer_list';
export const RQ_COMMON_SEASON_LIST = 'rq_common_season_list';
export const RQ_COMMON_SUPPLIER_LIST = 'rq_common_supplier_list';
export const RQ_COMMON_LABEL_LIST = 'rq_common_label_list';
export const RQ_COMMON_CONFIG_DISPLAY = 'rq_common_config_display';

export const RQ_PRODUCT_COUNT = 'rq_product_count';
export const RQ_PRODUCT_PUBLISHING_STATUS_COUNT = 'rq_product_publishing_status_count';

// ERP color list
export const COLOR = [
  'Multicolor',
  'Beige',
  'Black',
  'Blue',
  'Brown',
  'Gold',
  'Green',
  'Grey',
  'Navy',
  'Orange',
  'Pink',
  'Purple',
  'Red',
  'Silver',
  'White',
  'Yellow'
];

export const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const perPageOptions = [
  { id: 10, name: '10' },
  { id: 20, name: '20' },
  { id: 25, name: '25' },
  { id: 50, name: '50' }
];

export const COLOR_OPTIONS = COLOR.map((item) => ({ id: item, name: item }));
