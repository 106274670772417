import lazyLoader from 'helpers/lazy-loader';

const RetailerFormLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "retailer-form" */ './retailer-form')
);

const RetailerForm = (props: any) => {
  return <RetailerFormLoadable {...props} />;
};

export default RetailerForm;
